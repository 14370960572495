import React from 'react'
import { css } from '@emotion/core'
import Fade from 'react-reveal/Fade'
import {
  colors,
  breakpoints,
  spacer,
} from '@dqp/common/styles/utilities'
import Title from '@dqp/common/components/Title/Title'
import Text from '@dqp/common/components/Text/Text'
import Image from '#components/Image'
import {
  reactRevealDuration,
  reactRevealDistance,
} from '#globals/constants'

const styles = {
  container: css`
    background-color: ${colors.purple};
    ${breakpoints({
      paddingTop: [80, 80, 90, 120, 120],
      paddingBottom: [80, 80, 90, 40, 40],
    })};
  `,
  title: css`
    ${spacer.mrB30}
  `,
  text: css`
    line-height: 150%;
  `,
  mobile: css`
    right: 0;
    ${breakpoints({
      top: [0, 0, -200, -400, -400],
    })};
  `,
  books: css`
    left: 0;
    ${breakpoints({
      top: [-100, -100, -180, -250, -250],
      left: [-100, -100, -180, -250, -260],
    })};
  `,
  icecream: css`
    ${breakpoints({
      right: [null, null, null, -100, -100],
      top: [null, null, null, -370, -370],
    })};
  `,
}

function ZSection() {
  const titleStyleProps = {
    size: 'xxxxLarge',
    weight: 'bold',
    color: colors.white,
    css: styles.title,
  }
  return (
    <section css={styles.container}>
      <div className='container relative'>
        <div
          className='row align-items-center'
          css={css`
            ${breakpoints({
              marginBottom: [80, 80, 90, 150, 150],
            })};
          `}
        >
          <Fade
            left
            distance={reactRevealDistance}
            duration={reactRevealDuration}
          >
            <div className='col-lg-5 col-md-3 relative'>
              <Image
                lazy
                css={styles.mobile}
                className='d-none d-md-block'
                alt='mobile '
                image='howitworks/mobile'
                sizes={{
                  lg: {
                    width: 564,
                  },
                  md: {
                    width: 350,
                  },
                }}
              />
            </div>
          </Fade>
          <div className='col-lg-1 col-md-1' />
          <Fade clear cascade duration={reactRevealDuration}>
            <div className='col-lg-5 col-md-8 text-center text-md-left'>
              <Title as='h3' {...titleStyleProps}>
                Accurate and effective
              </Title>
              <Text
                size='large'
                color={colors.white}
                css={styles.text}
              >
                Our first programme covers Spatial Reasoning. The
                content has been created by an expert who writes the
                questions for the actual 11+ exams. We include
                official question types that are not covered in any
                other practice resources. This provides your child
                with a critical advantage in their competitive entry
                11+ exam.
              </Text>
            </div>
          </Fade>
          <div className='col-lg-1 col-md-1' />
        </div>

        <div
          className='row align-items-center'
          css={css`
            ${breakpoints({
              marginBottom: [0, 0, 40, 200, 200],
            })};
          `}
        >
          <div className='col-lg-1 col-md-0' />
          <Fade clear cascade duration={reactRevealDuration}>
            <div className='col-lg-5 col-md-8 text-center text-md-left'>
              <Title as='h3' {...titleStyleProps}>
                Created by examination experts
              </Title>
              <Text
                size='large'
                color={colors.white}
                css={styles.text}
              >
                All the content in our programmes is created, edited,
                reviewed and proofed by examination experts. We’ve
                been doing this for a long time and we know exactly
                what is required to do well in the 11+.
              </Text>
            </div>
          </Fade>
          <div className='col-lg-1 col-md-1' />
          <Fade
            right
            distance={reactRevealDistance}
            duration={reactRevealDuration}
          >
            <div
              className='col-lg-5 col-md-3 relative'
              style={{ zIndex: 3 }}
            >
              <Image
                lazy
                css={styles.books}
                className='d-none d-md-block'
                alt='books'
                image='howitworks/books'
                sizes={{
                  lg: {
                    width: 988,
                  },
                  md: {
                    width: 500,
                  },
                }}
              />
            </div>
          </Fade>
        </div>
        <Image
          lazy
          css={styles.icecream}
          className='d-none d-lg-block'
          alt='icecream'
          image='howitworks/icecream'
          sizes={{
            lg: {
              width: 354,
            },
            md: {
              width: 2,
            },
          }}
        />
      </div>
    </section>
  )
}

export default ZSection
