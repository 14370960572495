import React from 'react'
import { css } from '@emotion/core'
import Fade from 'react-reveal/Fade'
import {
  colors,
  breakpoints,
  spacer,
} from '@dqp/common/styles/utilities'
import Title from '@dqp/common/components/Title/Title'
import Text from '@dqp/common/components/Text/Text'
import Image from '#components/Image'
import { reactRevealDuration } from '#globals/constants'

const styles = {
  contaner: css`
    background-color: ${colors.black};
    ${breakpoints({
      paddingTop: [80, 80, 120, 120, 120],
      paddingBottom: [80, 80, 120, 270, 270],
    })};
  `,
  title: css`
    ${spacer.mrB30}
  `,
  text: css`
    line-height: 1.5;
  `,
  img: css`
    position: relative;
    z-index: 1;
  `,
  paintBucket: css`
    ${breakpoints({
      width: ['50%', '50%', '50%', '100%', '100%'],
      marginBottom: ['-250px', '-250px', '-350px', 0],
    })};
  `,
  confetti: css`
    ${breakpoints({
      top: [0, 0, -250, -300, -300],
      right: [0, 0, -150, -150, -150],
    })};
  `,
}
function VideoTutorials() {
  const titleStyleProps = {
    size: 'xxxxLarge',
    weight: 'bold',
    color: colors.white,
    css: styles.title,
  }
  return (
    <section css={styles.contaner}>
      <div className='container relative'>
        <div className='row justify-content-center align-items-center'>
          <Fade clear cascade duration={reactRevealDuration}>
            <div className='col-lg-10 text-center'>
              <Title as='h2' {...titleStyleProps}>
                Detailed video tutorials
              </Title>
              <Text
                size='large'
                color={colors.white}
                css={styles.text}
              >
                The video tutorial begins by going over the question
                in detail, explaining the correct method to arrive at
                the answer as well as correcting the misconceptions
                behind each of the wrong answer choices. However,
                that’s not all. Our expert tutors will then use the
                question as a basis to teach important concepts and
                topics that will help your child ace their exam. Each
                video tutorial is packed with actionable tips and
                insights.
                <br />
                <br />
                There is no limit to how many times you can watch each
                tutorial video.
              </Text>
            </div>
          </Fade>
        </div>
        <Image
          lazy
          css={styles.confetti}
          className='d-none d-md-block'
          alt='confetti bucket'
          image='howitworks/confetti'
          sizes={{
            lg: {
              width: 261,
            },
            md: {
              width: 200,
            },
          }}
        />
      </div>
    </section>
  )
}

export default VideoTutorials
