import React from 'react'
import { colors } from '@dqp/common/styles/utilities'
import PublicLayout from '#components/PublicLayout'
import SEO from '#components/SEO'
import HowItWorks from '#containers/HowItWorks/HowItWorks'
import heroimage from '#images/hero/howitworks_hero.png'

const HowItWorksPage = () => (
  <PublicLayout headerColor={colors.red}>
    <SEO
      title='How it works'
      meta={[{ name: 'theme-color', content: colors.red }]}
      image={heroimage}
    />
    <HowItWorks />
  </PublicLayout>
)

export default HowItWorksPage
