import React from 'react'
import Wave from '@dqp/common/components/Wave/Wave'
import { colors } from '@dqp/common/styles/utilities'
import Hero from './Hero'
import VideoTutorials from './VideoTutorials'
import PerformanceTracking from './PerformanceTracking'
import ZSection from './ZSection'
import PricingCTA from './PricingCTA'

function HowItWorks() {
  return (
    <>
      <Hero />
      <Wave bg={colors.red} fill={colors.black} type='1' />
      <VideoTutorials />
      <Wave bg={colors.black} fill={colors.white} type='3' />
      <PerformanceTracking />
      <Wave bg={colors.white} fill={colors.purple} type='1' />
      <ZSection />
      <Wave bg={colors.purple} fill={colors.yellow} type='3' />
      <PricingCTA />
      <Wave bg={colors.yellow} fill={colors.white} type='footer' />
    </>
  )
}

export default HowItWorks
