import React, { useRef, useEffect } from 'react'
import { css } from '@emotion/core'
import Fade from 'react-reveal/Fade'
import { Power0 } from 'gsap'
import {
  colors,
  breakpoints,
  spacer,
} from '@dqp/common/styles/utilities'
import Title from '@dqp/common/components/Title/Title'
import Text from '@dqp/common/components/Text/Text'
import Image from '#components/Image'
import {
  reactRevealDistance,
  reactRevealDuration,
  timelineY,
} from '#globals/constants'
import ScrollParallax from '#lib/ScrollParallax'

const styles = {
  contaner: css`
    background-color: ${colors.red};
    ${breakpoints({
      paddingTop: [40, 40, 120, 170, 170],
      paddingBottom: [80, 80, 120, 190, 190],
    })};
  `,
  title: css`
    ${spacer.mrB30}
  `,
  text: css`
    line-height: 1.5;
  `,
  img: css`
    position: relative;
    width: 100%;
    z-index: 1;
  `,
  paint: css`
    ${breakpoints({
      top: [0, 0, -250, -270, -270],
      right: [0],
    })};
  `,
}
function Hero() {
  const paint = useRef(null)
  const ctr = useRef(null)
  useEffect(() => {
    const painOptions = {
      options: {
        SMtriggerElement: ctr.current,
        SMduration: '100%',
        SMtriggerHook: 0.09,
        GSelement: paint.current,
        GSduration: 1,
      },
      timelineVarsStart: {
        y: timelineY.start,
        x: 0,
        rotation: 0,
        ease: Power0.easeNone,
      },
      timelineVarsEnd: {
        y: timelineY.end,
        x: 0,
        rotation: 0,
        ease: Power0.easeNone,
      },
    }

    const paintParallax = new ScrollParallax()

    paintParallax.init(
      painOptions.options,
      painOptions.timelineVarsStart,
      painOptions.timelineVarsEnd,
    )
    return () => {
      paintParallax.destroyController()
    }
  }, [])
  return (
    <section ref={ctr} css={styles.contaner}>
      <div className='container relative'>
        <div className='row align-items-center'>
          <Fade
            left
            distance={reactRevealDistance}
            duration={reactRevealDuration}
            ssrReveal
          >
            <div className='col-md-4'>
              <Image
                ref={paint}
                css={styles.paint}
                className='d-none d-md-block'
                alt='paint bucket'
                image='howitworks/paint'
                sizes={{
                  lg: {
                    width: 831,
                  },
                  md: {
                    width: 600,
                  },
                }}
              />
            </div>
          </Fade>
          <div className='offset-md-1 col-md-7 text-md-left text-center'>
            <Title
              as='h1'
              size='xxxxLarge'
              weight='bold'
              color={colors.black}
              css={styles.title}
            >
              Specially designed questions
            </Title>
            <Text size='large' color={colors.black} css={styles.text}>
              Every day, a specially designed multiple-choice question
              is released for your child to answer. Once complete, the
              question is marked automatically and your child is
              presented with a detailed video tutorial to watch.
            </Text>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Hero
