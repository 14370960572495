import React, { useRef, useEffect } from 'react'
import { css } from '@emotion/core'
import Fade from 'react-reveal/Fade'
import { Power0 } from 'gsap'
import {
  colors,
  breakpoints,
  spacer,
} from '@dqp/common/styles/utilities'
import Title from '@dqp/common/components/Title/Title'
import Text from '@dqp/common/components/Text/Text'
import Image from '#components/Image'
import { reactRevealDuration, timelineY } from '#globals/constants'
import ScrollParallax from '#lib/ScrollParallax'

const styles = {
  container: css`
    background-color: ${colors.white};
    ${breakpoints({
      paddingTop: [80, 80, 120, 120, 120],
      paddingBottom: [80, 80, 120, 200, 200],
    })};
  `,
  title: css`
    ${spacer.mrB30}
  `,
  text: css`
    line-height: 150%;
  `,
  img: css`
    position: relative;
    width: 100%;
    z-index: 1;
  `,

  bulb: css`
    ${breakpoints({
      top: [0, 0, 0, -750, -750],
      left: [0, 0, 0, -380, -315],
    })};
  `,
}
function PerformanceTracking() {
  const titleStyleProps = {
    size: 'xxxxLarge',
    weight: 'bold',
    color: colors.black,
    css: styles.title,
  }
  const bulb = useRef(null)

  useEffect(() => {
    const bulbOptions = {
      options: {
        SMtriggerElement: bulb.current,
        SMduration: '100%',
        SMtriggerHook: 0.09,
        GSelement: bulb.current,
        GSduration: 1,
      },
      timelineVarsStart: {
        y: timelineY.start,
        x: 0,
        rotation: 0,
        ease: Power0.easeNone,
      },
      timelineVarsEnd: {
        y: timelineY.end,
        x: 0,
        rotation: 0,
        ease: Power0.easeNone,
      },
    }

    const bulbParallax = new ScrollParallax()

    bulbParallax.init(
      bulbOptions.options,
      bulbOptions.timelineVarsStart,
      bulbOptions.timelineVarsEnd,
    )
    return () => {
      bulbParallax.destroyController()
    }
  }, [])
  return (
    <section css={styles.container}>
      <div className='container relative'>
        <div className='row justify-content-center align-items-center'>
          <Fade clear cascade duration={reactRevealDuration}>
            <div className='col-lg-10 text-center'>
              <Title as='h2' {...titleStyleProps}>
                Performance tracking
              </Title>
              <Text
                size='large'
                color={colors.charcoal}
                css={styles.text}
              >
                Questions are divided into categories and pitched at
                exam level difficulty. Your Insights Dashboard will
                therefore build a clear picture over time of your
                child’s strengths and weaknesses. You will be able to
                see which questions they got right and wrong and which
                categories they may need to focus more on. You will
                also be able to see how long they spent answering each
                question to gain insights into their time management.
              </Text>
            </div>
          </Fade>
        </div>
        <Image
          ref={bulb}
          lazy
          css={styles.bulb}
          className='d-none d-lg-block'
          alt='bulb bucket'
          image='howitworks/bulb'
          sizes={{
            lg: {
              width: 664,
            },
            md: {
              width: 200,
            },
          }}
        />
      </div>
    </section>
  )
}

export default PerformanceTracking
